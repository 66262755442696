.experience-suzano_einsten-einsteinCarouselUserSuzano{
    padding-top: 24px;
    padding-bottom: 24px;

    @media (max-width: 576px) {
        padding-top: 12px;
        padding-bottom: 12px;
    }

    .popularCatContainer{
        min-height: inherit !important;
    }

    .einsteinCarousel .productCarouselItem.skeleton-loaderCarousel{
        display: grid;
        overflow: hidden;
        column-gap: 12px;
        grid-template-columns: repeat(6, 1fr);
        grid-template-rows: 1fr 0 0 0 0 0;
        overflow: hidden;

        @media (max-width: 1280px) {
            grid-template-columns: repeat(4, 1fr);
        }
        @media (max-width: 992px) {
            grid-template-columns: repeat(3, 1fr);
        }
        @media (max-width: 768px) {
            grid-template-columns: repeat(2, 1fr);
        }
        @media (max-width: 576px) {
            grid-template-columns: repeat(2, 1fr);
        }
    }
}

.experience-component.experience-suzano_layouts-productListCarousel {
    padding: 24px 0;
}